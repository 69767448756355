import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import Axios from "axios";
import swal from 'sweetalert';


class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fetched: -1,
            loading: false,
            user_name: '',
            password: '',
            value: ''
        }

        this.loginhandle = this.loginhandle.bind(this);
    }


    loginhandle(e) {
        var user_name = this.refs.user_name.value;
        var password = this.refs.password.value;
        e.preventDefault();
        const dateObj = {
            user_name: user_name,
            password: password
        }

        this.setState({ loading: true }, () => {
            Axios.post('/dgx/login', dateObj)
                .then((res => {
                    console.log(res.data)
                    if (res.data === 2) {
                        swal("", "Invalid User Credentials!", "warning");
                    } else {
                        if (res.data[0]['is_active'] === 0) {
                            // this.props.history.push("/Home")
                            swal("", "Your account is not active!", "warning");
                        } else {
                            var token = res.data[0]['token'];
                            var port = res.data[0]['port'];
                            var ip = res.data[0]['ip'];
                            sessionStorage.setItem('user_id', user_name);
                            sessionStorage.setItem('token', token);
                            sessionStorage.setItem('port', port);
                            sessionStorage.setItem('ip', ip);
                            this.props.history.push("/Home")
                        }
                    }
                    this.setState({ loading: false, value: JSON.stringify(res.data) })
                }));

        });
    }

    render() {
        // console.log(this.state.value)
        // if(this.state.loading)  {
        // msg = (
        //     <Spin style={{ fontSize: '16px', position: 'relative', left: '5px', color:'white' }} tip="Loading..."></Spin>

        // )
        // }
        // else if(!this.state.loading) {
        //     <Spin style={{ fontSize: '16px', position: 'relative', left: '5px', color:'white' }} ></Spin>
        // }
        return (
            <>
                {/* <nav className="navbar navbar-expand-lg navbar-light fixed-top">
            <div className="container">
              <img src={'http://www.iiml.ac.in/themes/awesome_zymphonies_theme/images/foot-logo.png'} />
              <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
                <ul className="navbar-nav ml-auto">

                </ul>
              </div>
            </div>
          </nav> */}
                <div className="appAside" />
                <div className="appForm">
                    <div className="pageSwitcher">
                        <NavLink
                            to="/"
                            activeClassName="pageSwitcherItem-active"
                            className="pageSwitcherItem"
                        >
                            Sign In
                        </NavLink>
                        <NavLink
                            exact
                            to="/Signup"
                            activeClassName="pageSwitcherItem-active"
                            className="pageSwitcherItem"
                        >
                            Sign Up
                        </NavLink>
                    </div>
                </div>

                <div class="container"
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "70vh",
                        //  marginTop: '30px'
                    }}>

                    <div class="d-flex justify-content-center h-100">
                        <div class="card">
                            <div class="card-header">
                                <div align="center">
                                    {/* <img src="https://i.ibb.co/dPc17Gk/cai.png" width="60" height="60" alt="logo" /> */}
                                    {/* <div style={{float:'left'}}>
                                    {msg}
                                    <img src="http://www.iiml.ac.in/themes/awesome_zymphonies_theme/images/foot-logo.png" width="60" height="60" alt="logo" />
                                  </div>
                                  <div style={{float:'right'}}> */}
                                    <img
                                        src="iim_logo.jpeg"
                                        width="200" height="60" alt="logo" />
                                </div>
                                <div class="d-flex justify-content-end social_icon">
                                    {/* <span><i class="fab fa-facebook-square"></i></span>
                            <span><i class="fab fa-google-plus-square"></i></span>
                            <span><i class="fab fa-twitter-square"></i></span> */}
                                    {/* <input type="button" value="Sign In" class="btn float-right login_btn" />
                                <input type="button" value="Sign Up" class="btn float-right login_btn" /> */}
                                    {/* <span style={{backgroundColor:'green'}}>Sign Up</span> */}
                                    {/* <span>Sign In</span> */}
                                </div>
                                {/* <h3 style={{ textAlign: 'center' }}>Sign In</h3> */}
                            </div>
                            <div class="card-body">
                                {/* <Spin style={{ fontSize: '16px', position: 'relative', left: '110px' }} tip="Loading..."></Spin> */}
                                <form onSubmit={this.loginhandle}>
                                    <div class="input-group form-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><i class="fas fa-user"></i></span>
                                        </div>
                                        <input type="email" class="form-control" placeholder="Email ID" ref="user_name"
                                            required />

                                    </div>
                                    <div class="input-group form-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><i class="fas fa-key"></i></span>
                                        </div>
                                        <input type="password" class="form-control" placeholder="Password"
                                            ref="password" required />
                                    </div>
                                    <div class="row align-items-center remember">
                                        <input type="checkbox" />Remember Me
                                    </div>
                                    <div class="form-group">
                                        {/* <button type="submit" className="btn btn-primary btn-block">Login</button> */}
                                        <input type="submit" value="Login" class="btn float-right login_btn" />
                                    </div>
                                </form>
                            </div>
                            {/* <div class="card-footer">
                            <div class="d-flex justify-content-center links">
                                Don't have an account?<a href="#">Sign Up</a>
                            </div>
                            <div class="d-flex justify-content-center">
                                <a href="#">Forgot your password?</a>
                            </div>
                        </div> */}
                        </div>
                    </div>

                </div>
                <footer className="page-footer font-small blue">


                    <div className="footer-copyright text-right py-4"
                        style={{ color: 'white', marginRight: '20px', marginTop: '10px' }}><div><a href="mailto: support.ailab@iiml.ac.in">Contact Us</a></div><b>Powered by Constems-AI
                            Systems Pvt Ltd</b>
                        {/* <a href="https://mdbootstrap.com/"> MDBootstrap.com</a> */}
                    </div>


                </footer>
            </>
        );

    }
}

export default Login;