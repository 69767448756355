import React, {Component} from 'react';
import 'antd/dist/antd.css';
import {Card, Modal} from 'antd';
import Sidebar from "../sidebar/Sidebar";
import Header from "../header/Header"
import Axios from "axios";


class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            licenseModal: false,
            eulaStatus: 0
        }

        this.handleOk = this.handleOk.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    componentDidMount() {
        const userName = sessionStorage.getItem('user_id')
        Axios.get('dgx/eula-status?user=' + userName)
                 .then((res) => {
                     const eulaStatus = res.data["eula_status"]
                     let showEula = eulaStatus === 0;
                     this.setState({licenseModal: showEula})
                 })
    }

    volume = () => {
        this.props.history.push("/Volume")
    }
    workspace = () => {
        this.props.history.push("/WorkSpace")
    }

    handleOk() {
        const userName = sessionStorage.getItem('user_id')
        this.setState({licenseModal: false})
        const dataObj = {
            status: 1,
            user_name: userName
        }
        Axios.post('dgx/eula-status', dataObj)
            .then((res) => {})
    }

    handleCancel() {
        this.props.history.push("/")
    }

    render() {
        var user_name = sessionStorage.getItem('user_id')
        return (
            <>
                <Modal title="Please accept End User License Agreement to proceed" visible={this.state.licenseModal}
                       onOk={this.handleOk} onCancel={this.handleCancel} okText="Agree" cancelText="Disagree"
                       closable={false}>
                    <div style={{overflowY: "auto", maxHeight: "50vh"}}>
                        <h5>NVIDIA PRAKRITI AI LAB PLATFORM END USER LICENSE AGREEMENT</h5>
                        <h6>Release Date: December [.], 2021</h6>


                        <p>This End User License Agreement ("EULA”), made and entered into as of the time and date of
                            click through action (“Effective Date”), is a legal agreement between you and IIM Lucknow
                            Enterprise Incubation Centre ("IIMLEIC") and governs the use of the NVIDIA computer software
                            and the documentation made available for use with such NVIDIA software on the Prakriti AI
                            Lab platform (“Platform”) established by IIML EIC . By downloading, installing, copying, or
                            otherwise using the NVIDIA software on Prakriti AI Lab platform and/or documentation, you
                            agree to be bound by the terms of this EULA. If you do not agree to the terms of this EULA,
                            do not download, install, copy or use the NVIDIA software or documentation through Prakriti
                            AI Lab platform. IF YOU ARE ENTERING INTO THIS EULA ON BEHALF OF A COMPANY OR OTHER LEGAL
                            ENTITY, YOU REPRESENT THAT YOU HAVE THE LEGAL AUTHORITY TO BIND THE ENTITY TO THIS EULA, IN
                            WHICH CASE “YOU” WILL MEAN THE ENTITY YOU REPRESENT. IF YOU DON’T HAVE SUCH AUTHORITY, OR IF
                            YOU DON’T ACCEPT ALL THE TERMS AND CONDITIONS OF THIS EULA, THEN IIMLEIC DOES NOT AGREE TO
                            ALLOW YOU THE USAGE OF THE LICENSED SOFTWARE, AND YOU MAY NOT DOWNLOAD, INSTALL, COPY OR USE
                            IT.</p>

                        <h5>1. LICENSE.</h5>

                        <p>1.1 License use Grant. Subject to the terms of this EULA, IIMLEIC hereby grants you
                            permission to use NVIDIA software through login from Prakriti AI Lab platform during the
                            term of this EULA to use the platform and Software for compute purposes and, if applicable,
                            use Documentation provided with the Software as part of a software as a service solution
                            provided to you by an approved NVIDIA cloud service provider. Compute purposes expressly
                            excludes interactive graphics functionality.</p>

                        <p>1.2 Enterprise and Contractor Usage. You may allow your Enterprise employees and Contractors
                            to access and use the Licensed Software pursuant to the terms in Section 1 solely to perform
                            work on your behalf, provided further that with respect to Contractors: (i) you obtain a
                            written agreement from each Contractor which contains terms and obligations with respect to
                            use of Software no less protective of NVIDIA than those set forth in this EULA, and (ii)
                            such Contractor’s access and use expressly excludes any sublicensing or distribution rights
                            for the Licensed Software. You are responsible for the compliance with the terms and
                            conditions of this EULA by your Enterprise and Contractors. Any act or omission that if
                            committed by you would constitute a breach of this EULA shall be deemed to constitute a
                            breach of this EULA if committed by your Enterprise or Contractors.</p>
                        <h5>2. LIMITATIONS.</h5>

                        <p>2.1 License Restrictions. Except as expressly authorized in this EULA, you agree that you
                            will not (nor allow third parties to): (i) copy and use Software outside of the authorized
                            software as a service solution; (ii) reverse engineer, decompile, disassemble (except to the
                            extent applicable laws specifically require that such activities be permitted) or attempt to
                            derive the source code, underlying ideas, algorithm or structure of Software provided to you
                            in object code form; (iii) sell, transfer, assign, distribute, rent, loan, lease, sublicense
                            or otherwise make available the Licensed Software or its functionality to third parties (a)
                            as an application services provider or service bureau, (b) by operating hosted/virtual
                            system environments, (c) by hosting, time sharing or providing any other type of services,
                            or (d) otherwise by means of the internet; (iv) modify, translate or otherwise create any
                            derivative works of any Licensed Software; (v) remove, alter, cover or obscure any
                            proprietary notice that appears on or with the Licensed Software or any copies thereof; (vi)
                            use the Licensed Software, or allow its use, transfer, transmission or export in violation
                            of any applicable export control laws, rules or regulations; (vii) distribute, permit access
                            to, or sublicense the Licensed Software as a stand-alone product; (viii) bypass, disable,
                            circumvent or remove any form of copy protection, encryption, security or digital rights
                            management or authentication mechanism used by NVIDIA or further enhance by IIML-EIC in
                            connection with the Licensed Software, or use the Licensed Software together with any
                            authorization code, serial number, or other copy protection device not supplied by NVIDIA
                            directly or through an authorized reseller; (ix) use the Licensed Software for the purpose
                            of developing competing products or technologies or assisting a third party in such
                            activities; (x) use the Licensed Software with any system or application where the use or
                            failure of such system or application can reasonably be expected to threaten or result in
                            personal injury, death, or catastrophic loss including, without limitation, use in
                            connection with any nuclear, avionics, navigation, military, medical, life support or other
                            life critical application (“Critical Applications”); or (xii) use the Licensed Software in
                            any manner that would cause the Licensed Software to become subject to an Open Source
                            License. Nothing in this EULA shall be construed to give you a right to use, or otherwise
                            obtain access to, any source code from which the Software or any portion thereof is compiled
                            or interpreted. You acknowledge that NVIDIA or IIML-EIC does not design, test, manufacture
                            or certify the Licensed Software for use in the context of a Critical Application and NVIDIA
                            or IIML-EIC shall not be liable to you or any third party, in whole or in part, for any
                            claims or damages arising from such use. You agree to defend, indemnify and hold harmless
                            NVIDIA or IIML-EIC and its Affiliates, and their respective employees, contractors, agents,
                            officers and directors, from and against any and all claims, damages, obligations, losses,
                            liabilities, costs or debt, fines, restitutions and expenses (including but not limited to
                            attorney’s fees and costs incident to establishing the right of indemnification) arising out
                            of or related to you and your Enterprise, and their respective employees, contractors,
                            agents, distributors, resellers, end users, officers and directors use of Licensed Software
                            for any breach of the terms of this EULA.</p>

                        <p>2.2 Third Party License Obligations. You acknowledge and agree that the Licensed Software may
                            include or incorporate third party technology (collectively “Third Party Components”), which
                            is provided for use in or with the Software and not otherwise used separately.</p>

                        <p>2.3 Limited Rights. Your rights in the Licensed Software are limited to those expressly
                            granted in Section 1 and no other licenses are granted whether by implication, estoppel or
                            otherwise. IIML EIC reserves all other rights, title and interest in and to the Licensed
                            Software not expressly granted under this EULA by NVIDIA.</p>

                        <h5>3. CONFIDENTIALITY.</h5> <p>Neither party will use the other party’s Confidential
                        Information, except as necessary for the performance of this EULA, nor will either party
                        disclose such Confidential Information to any third party, except to personnel of NVIDIA or
                        IIMLEIC or its Affiliates, you, your Enterprise or your Contractors that have a need to know
                        such Confidential Information for the performance of this EULA, provided that each such
                        personnel, employee and Contractor is subject to a written agreement that includes
                        confidentiality obligations consistent with those set forth herein. Each party will use all
                        reasonable efforts to maintain the confidentiality of all of the other party’s Confidential
                        Information in its possession or control, but in no event less than the efforts that it
                        ordinarily uses with respect to its own Confidential Information of similar nature and
                        importance. The foregoing obligations will not restrict either party from disclosing the other
                        party’s Confidential Information or the terms and conditions of this EULA as required under
                        applicable securities regulations or pursuant to the order or requirement of a court,
                        administrative agency, or other governmental body, provided that the party required to make such
                        disclosure (i) gives reasonable notice to the other party to enable it to contest such order or
                        requirement prior to its disclosure (whether through protective orders or otherwise), (ii) uses
                        reasonable effort to obtain confidential treatment or similar protection to the fullest extent
                        possible to avoid such public disclosure, and (iii) discloses only the minimum amount of
                        information necessary to comply with such requirements.</p>

                        <h5>4. OWNERSHIP.</h5> <p>The Licensed Software and all modifications, and the respective
                        Intellectual Property Rights therein, are and will remain the sole and exclusive property of
                        NVIDIA or its licensors, whether the Licensed Software is separate from or combined with any
                        other products or materials. You shall not engage in any act or omission that would impair
                        NVIDIA’s and/or its licensors’ Intellectual Property Rights in the Licensed Software or any
                        other materials, information, processes or subject matter proprietary to NVIDIA. NVIDIA’s
                        licensors are intended third party beneficiaries with the right to enforce provisions of this
                        EULA with respect to their Confidential Information and/or Intellectual Property Rights.</p>

                        <h5>5. FEEDBACK.</h5> <p>You have no obligation to provide Feedback to NVIDIA or IIMLEIC.
                        However, NVIDIA and/or its Affiliates or IIMLEIC may use and include any Feedback that you
                        provide to improve the Licensed Software or other NVIDIA products, technologies or
                        materials.</p>


                        <h5>6. NO WARRANTIES.</h5> <p>THE LICENSED SOFTWARE AND NVIDIA CONFIDENTIAL INFORMATION (IF ANY
                        PROVIDED) ARE PROVIDED BY NVIDIA “AS IS” AND “WITH ALL FAULTS,” AND NVIDIA EXPRESSLY DISCLAIMS
                        ALL OTHER WARRANTIES OF ANY KIND OR NATURE, WHETHER EXPRESS, IMPLIED OR STATUTORY, INCLUDING,
                        BUT NOT LIMITED TO, ANY WARRANTIES OF OPERABILITY, CONDITION, VALUE, ACCURACY OF DATA, OR
                        QUALITY, AS WELL AS ANY WARRANTIES OF MERCHANTABILITY, SYSTEM INTEGRATION, WORKMANSHIP,
                        SUITABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON- INFRINGEMENT, OR THE ABSENCE OF ANY DEFECTS
                        THEREIN, WHETHER LATENT OR PATENT AND IIMLEIC ADHERS TO THE SAME FOR ITS USE BY THE USER.</p>

                        <h5>7. LIMITATION OF LIABILITY.</h5> <p>TO THE MAXIMUM EXTENT PERMITTED BY LAW, NVIDIA OR ITS
                        LICENSORS SHALL NOT BE LIABLE FOR ANY SPECIAL, INCIDENTAL, PUNITIVE OR CONSEQUENTIAL DAMAGES
                        (INCLUDING, WITHOUT LIMITATION, LOST PROFITS, LOSS OF USE, LOSS OF DATA OR LOSS OF GOODWILL), OR
                        THE COSTS OF PROCURING SUBSTITUTE PRODUCTS, ARISING OUT OF OR IN CONNECTION WITH THIS EULA OR
                        THE USE OR PERFORMANCE OF THE LICENSED SOFTWARE AND NVIDIA CONFIDENTIAL INFORMATION (IF ANY
                        PROVIDED), WHETHER SUCH LIABILITY ARISES FROM ANY CLAIM BASED UPON BREACH OF CONTRACT, BREACH OF
                        WARRANTY, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR ANY OTHER CAUSE OF ACTION OR THEORY
                        OF LIABILITY AND WHETHER OR NOT NVIDIA HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. The
                        disclaimers, exclusions and limitations of liability set forth in this EULA form an essential
                        basis of the bargain between the parties, and, absent any such disclaimers, exclusions or
                        limitations of liability, the provisions of this EULA, including, without limitation, the
                        economic terms, would be substantially different.</p>

                        <h5>8. TERM AND TERMINATION.</h5> <p>This EULA and your license rights hereunder shall become
                        effective upon the Effective Date and shall remain in effect for the duration of your licenses,
                        unless earlier terminated as provided in this section. This EULA may be terminated upon written
                        notice in the event of breach of any of the terms of this EULA. Termination of this EULA shall
                        not release the parties from any liability which, at the time of termination, has already
                        accrued or which thereafter may accrue with respect to any act or omission before termination,
                        or from any obligation which is expressly stated in this EULA to survive termination.
                        Notwithstanding the foregoing, the party terminating this EULA shall incur no additional
                        liability merely by virtue of such termination. Termination of this EULA regardless of cause or
                        nature shall be without prejudice to any other rights or remedies of the parties and shall be
                        without liability for any loss or damage occasioned thereby. Upon any expiration or termination
                        of this EULA (i) you must promptly discontinue use of the Licensed Software, and (ii) you must
                        promptly destroy or return to NVIDIA all copies of the Licensed Software and all portions
                        thereof in your possession or control, and each party will promptly destroy or return to the
                        other all of the other party’s Confidential Information within its possession or control. Upon
                        written request, you will certify in writing that you have complied with your obligations under
                        this section. Sections 2 through 10 will survive the expiration or termination of this EULA for
                        any reason.</p>

                        <h5>9. CONSENT TO COLLECTION AND USE OF INFORMATION.</h5>

                        <p>You hereby agree and acknowledge that the Software may access, collect non-personally
                            identifiable information about, update, and configure your Enterprise computer systems in
                            order to (a) properly optimize such systems for use with the Software, (b) deliver software
                            and services, or content through the Software, (c) optimize, maintain, repair and/or
                            administer NVIDIA products and services, and/or (d) deliver marketing communications.
                            Information collected by the Software includes, but is not limited to, Customer System's (i)
                            hardware configuration and ID, (ii) operating system and driver configuration, (iii)
                            installed applications, (iv) applications settings, performance, and usage metrics, and (iv)
                            usage metrics of the Software. To the extent that you use the Software, you hereby consent
                            to all of the foregoing, and represent and warrant that you have the right to grant such
                            consent. In addition, you agree that you are solely responsible for maintaining appropriate
                            data backups and system restore points for your Enterprise systems, and that NVIDIA will
                            have no responsibility for any damage or loss to such systems (including loss of data or
                            access) arising from or relating to (a) any changes to the configuration, application
                            settings, environment variables, registry, drivers, BIOS, or other attributes of the systems
                            (or any part of such systems) initiated through the Software; or (b) installation of any
                            Software or third party software patches initiated through the Software.</p>


                        <p>In connection with the receipt of the Licensed Software you may receive access to links to
                            third party websites and services and the availability of those links does not imply any
                            endorsement by NVIDIA. NVIDIA encourages you to review the privacy statements on those sites
                            and services that you choose to visit so that you can understand how they may collect, use
                            and share personal information of individuals. NVIDIA is not responsible or liable for: (i)
                            the availability or accuracy of such links; or (ii) the products, services or information
                            available on or through such links; or (iii) the privacy statements or practices of sites
                            and services controlled by other companies or organizations.</p>

                        <h5>10. MISCELLANEOUS.</h5>

                        <p>10.1 Compliance with Terms. During the term of this EULA and for a period of three (3) years
                            thereafter, you will maintain all usual and proper books and records of account relating to
                            the Licensed Software provided under this EULA and to cooperate with your cloud service
                            provider or its Affiliates to verify your compliance with the terms of this EULA. You
                            further agree that your cloud service provider or its Affiliates and NVIDIA may exchange
                            information regarding your use of the Licensed Software and your compliance with the terms
                            of this EULA.</p>
                    </div>
                </Modal>
                <div style={{
                    width: '100%', height: '90vh', display: "flex",
                    justifyContent: "center",
                    backgroundColor: 'rgba(255, 255, 255, 1)'
                    // alignItems: "center",
                }}>


                    <Sidebar pageNumber='1'></Sidebar>
                    <Header user_name={user_name}></Header>

                    <div style={{
                        width: '100%', display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: '50px'
                    }}>

                        <Card hoverable style={{width: '130px', height: '120px', border: '3px solid transparent'}}
                              onClick={this.workspace}>
                            <div style={{
                                width: '100%',
                                justifyContent: "center",
                                alignItems: "center",
                                // marginBottom:'10px'
                            }}>
                                <div style={{width: '100%', marginLeft: '10px'}}>
                                    {/* <i className={"fas fa-store"} style={{ fontSize: '35px' }}></i> */}
                                    <img src="https://i.ibb.co/XYPzqmr/code.png" alt="home" border="0"
                                         style={{width: '60px'}}/>

                                </div>

                                <h6 style={{marginTop: '2px'}}>WorkSpace</h6>
                            </div>


                        </Card>

                        <Card hoverable style={{
                            width: '130px',
                            height: '120px',
                            border: '3px solid transparent',
                            marginLeft: '20px'
                        }} onClick={this.volume}>
                            <div style={{
                                width: '100%',
                                justifyContent: "center",
                                alignItems: "center",
                            }}>
                                <div style={{width: '100%', marginLeft: '10px'}}>
                                    {/* <i className={"fas fa-compact-disc"} style={{ fontSize: '35px' }}></i> */}
                                    <img src="https://i.ibb.co/njHchy2/ssd.png" alt="home" border="0"
                                         style={{width: '60px'}}/>

                                </div>

                                <h6 style={{marginTop: '5px', marginLeft: '12px'}}>Volume</h6>
                            </div>

                        </Card>
                    </div>

                </div>
                <div
                    style={{
                        width: '100%',
                        height: '10vh',
                        display: 'flex',
                        // display:'inline-flex',
                        backgroundColor: 'rgba(255, 255, 255, 1)',
                        // border:'1px solid #eee',
                        // boxShadow:'2px 10px #ebebeb',
                        // justifyContent: "center",
                        alignItems: "center",
                        justifyContent: "right"
                        // marginLeft:'20px'

                    }}>

                    <footer className="page-footer font-small blue">
                        <div className="footer-copyright text-right" style={{color: 'grey', marginRight: '20px',}}><b>Powered
                            by Constems-AI Systems Pvt. Ltd</b>
                        </div>


                    </footer>
                </div>
            </>
        );
    }
}

export default Home;
