import React, { Component } from 'react';
import { HashRouter as Router, Route, NavLink } from "react-router-dom";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.updateDate = this.updateDate.bind(this);
    
    this.state = {
      date: new Date().toLocaleTimeString(),
    }
    this.interval = setInterval(this.updateDate, 1000);
  }
  
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  
  updateDate() {
    this.setState({
      date: new Date().toLocaleTimeString(),
    });
  }


  

  render() {
   
    return (
      <div>
        <div className="icon-bar">
       
          <NavLink
            exact
            to="/Home"  >
           <img  src="https://i.ibb.co/FXwW0Ng/home.png" alt="home"  />
            {/* <i className={"fa fa-home " } ></i> */}
          </NavLink>
      
          <NavLink
            exact
            to="/WorkSpace"

          >
            {/* <i className={"fas fa-store" } ></i> */}
            <img src="https://i.ibb.co/XYPzqmr/code.png" alt="home"   />
            
          </NavLink>
        
          <NavLink
            exact
            to="/Volume"

          >
            {/* <i className="fas fa-compact-disc"></i> */}
            {/* <i className="fas fa-disc-drive"></i> */}
            {/* <i className={"fa fa-globe"} ></i> */}
            <img src="https://i.ibb.co/njHchy2/ssd.png" alt="home"   />
          </NavLink>
         
          <NavLink
            exact
            to="/"

          >
            {/* <i className="fa fa-sign-out-alt" ></i> */}
            <img src="https://i.ibb.co/2KFynm1/logout-1.png" alt="home" />
          </NavLink>
         
          <div align="center" style={{bottom:'10px'}}>
          {/* <br></br> */}
          <b style={{color:'black', textAlign:'center', 
    }}>{this.state.date}</b>
          </div>
          
        </div>
       
      </div>
    );
  }
}

export default Sidebar;