import React, { Component } from 'react';

class Header extends Component {
    render() {
        var user_name = this.props.user_name;
        return (
            <div>
                {/* <nav className="navbar navbar-expand-lg navbar-light  fixed-top">

                    <div className="container-fluid" >

                        <h5 style={{ marginLeft:'70%', marginTop:'10px' }}><b>User Name: </b>{this.props.user_name} </h5>


                    </div>
                </nav> */}
                  <div className="fixed-header">
        <div className="container-fluid">
           
           <div >
               <h5 style={{float:'left', color:'grey',position:'relative', right:'100px'}}><b style={{position:'relative', top:'8px'}}>Prakriti AI</b></h5>
               {/* <h6 style={{float:'center', color:'grey'}}>CAI DGX</h6> */}
                <h6 style={{float:'right', color:'grey'}}><b style={{position:'relative', bottom:'5px', right:'5px'}}>{user_name}</b><i className={"fa fa-user-circle" }  style={{fontSize:'30px'}}></i></h6>
           </div>
            {/* <nav style={{float:'right'}}>
                <a href="#">Home</a>
                <a href="#">About</a>
                <a href="#">Products</a>
                <a href="#">Services</a>
                <a href="#">Contact Us</a>
            </nav> */}
        </div>
    </div>

{/* <nav className="navbar navbar-inverse">
  <div className="container-fluid"> */}
    {/* <div className="navbar-header">
      <a className="navbar-brand" href="#">WebSiteName</a>
    </div>
    <ul className="nav navbar-nav">
      <li className="active"><a href="#">Home</a></li>
      <li className="dropdown"><a className="dropdown-toggle" data-toggle="dropdown" href="#">Page 1 <span className="caret"></span></a>
        <ul className="dropdown-menu">
          <li><a href="#">Page 1-1</a></li>
          <li><a href="#">Page 1-2</a></li>
          <li><a href="#">Page 1-3</a></li>
        </ul>
      </li>
      <li><a href="#">Page 2</a></li>
    </ul> */}
    {/* <ul className="nav navbar-nav navbar-right">
      <li><a href="#"><span className="glyphicon glyphicon-user"></span> Sign Up</a></li>
      <li><a href="#"><span className="glyphicon glyphicon-log-in"></span> Login</a></li>
    </ul>
  </div>
</nav> */}
            </div>
        );
    }
}

export default Header;