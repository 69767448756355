import React, { Component } from "react";
import { HashRouter as Router, Route, NavLink } from "react-router-dom";
import { Spin } from "antd";
import Axios from "axios";
import swal from "sweetalert";
import { Select } from "antd";
const { Option } = Select;

class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetched: -1,
      loading: false,
      user_name: "",
      password: "",
      organization: "IIML EIC - IIML Incubator",
      value: "",
      organizationList: [],
    };
    this.signupHandle = this.signupHandle.bind(this);
    this.handleORG = this.handleORG.bind(this);
    this.fetchOrganizationNames = this.fetchOrganizationNames.bind(this);
  }

  componentDidMount() {
    // Fetch organization names when component mounts
    this.fetchOrganizationNames();
  }

  fetchOrganizationNames() {
    console.log("fetching");
    Axios.get("dgx/organizations")
      .then((response) => {
        this.setState({ organizationList: response.data });
      })
      .catch((error) => {
        console.error("Error fetching organization names:", error);
      });
  }

  handleORG(organization) {
    this.setState({ organization });
  }

  signupHandle(e) {
    var user_name = this.refs.user_name.value;
    var password = this.refs.password.value;
    var organization = this.state.organization;
    e.preventDefault();
    const dateObj = {
      user_name: user_name,
      password: password,
      organisation: organization,
    };
    // sessionStorage.setItem('user_id', user_name);
    this.setState({ loading: true }, () => {
      Axios.post("/dgx/create-account", dateObj).then((res) => {
        if (res.data === 1) {
          swal(
            "You have signed up successfully",
            "Your request is under process and it will be updated within 12 hours.",
            "success"
          );
          this.props.history.push("/");
        } else if (res.data === 2) {
          swal("", "email id already exists!", "warning");
        }
        this.setState({ loading: false, value: JSON.stringify(res.data) });
      });
    });
  }

  render() {
    const { organizationList } = this.state;
    return (
      <>
        <div className="appAside" />
        <div className="appForm">
          <div className="pageSwitcher">
            <NavLink
              to="/"
              activeClassName="pageSwitcherItem-active"
              className="pageSwitcherItem"
            >
              Sign In
            </NavLink>
            <NavLink
              exact
              to="/Signup"
              activeClassName="pageSwitcherItem-active"
              className="pageSwitcherItem"
            >
              Sign Up
            </NavLink>
          </div>
        </div>

        <div
          class="container"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "70vh",
            //  marginTop: '30px'
          }}
        >
          <div class="d-flex justify-content-center h-100">
            <div class="card1">
              <div class="card-header">
                {/* <h3 style={{textAlign:'center'}}>Sign Up</h3> */}
                <div align="center">
                  <img
                    // src="https://cms.iimlincubator.com/uploads/IIMLIncubator_Logo_final_v1_white_background_f30e73134c.jpeg"
                    src="iim_logo.jpeg"
                    width="200"
                    height="60"
                    alt="logo"
                  />
                </div>
              </div>

              <div class="card-body">
                <form onSubmit={this.signupHandle}>
                  <div class="input-group form-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="fas fa-user"></i>
                      </span>
                    </div>
                    {/* <input type="email" class="form-control" placeholder="username" ref="user_name" required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{3,}$" />
                     */}
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Email ID"
                      ref="user_name"
                      required
                      pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{3,2}$"
                    />
                  </div>

                  <div class="input-group form-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="fas fa-key"></i>
                      </span>
                    </div>
                    <input
                      type="password"
                      class="form-control"
                      placeholder="Password"
                      ref="password"
                      required
                      pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                      title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
                    />
                  </div>

                  <div class="input-group form-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="fas fa-building"></i>
                      </span>
                    </div>

                    <Select
                      showSearch
                      style={{ width: 300 }}
                      defaultValue="IIML EIC - IIML Incubator"
                      // placeholder="Select organization"
                      onChange={this.handleORG}
                    >
                      {organizationList.map((org) => (
                        <Option key={org} value={org}>
                          {org}
                        </Option>
                      ))}
                    </Select>
                    {/* <input type="text" class="form-control" placeholder="organization" ref="organization" required /> */}
                  </div>
                  {/* <div class="row align-items-center remember">
                                <input type="checkbox" />Remember Me
                            </div> */}
                  <div align="center">
                    <div class="form-group">
                      <input
                        type="submit"
                        value="Signup"
                        class="btn  login_btn"
                      />
                    </div>
                  </div>
                </form>
              </div>
              {/* <div class="card-footer">
                        <div class="d-flex justify-content-center links">
                            Don't have an account?<a href="#">Sign Up</a>
                        </div>
                        <div class="d-flex justify-content-center">
                            <a href="#">Forgot your password?</a>
                        </div>
                    </div> */}
            </div>
          </div>
        </div>
        <footer className="page-footer font-small blue">
          <div
            className="footer-copyright text-right py-4"
            style={{ color: "white", marginRight: "20px", marginTop: "10px" }}
          >
            <b>Powered by Constems-AI Systems Pvt. Ltd</b>
            {/* <a href="https://mdbootstrap.com/"> MDBootstrap.com</a> */}
          </div>
        </footer>
      </>
    );
  }
}

export default Signup;
