import React, { Component } from 'react';
import Axios from "axios";
import Sidebar from "../sidebar/Sidebar";
import Header from "../header/Header"


class Workspace extends Component {

    constructor(props) {
        super(props);
        this.state = {
            url: ''

        }

    }
//    yy
   
    render() {
    
        var url = '';
        // var ip = 'http://172.16.0.4:';
        // var ip = 'http://20.197.24.33:';
        var ip = sessionStorage.getItem('ip');
        var port = sessionStorage.getItem('port');
        var user_name = sessionStorage.getItem('user_id')
        var token = sessionStorage.getItem('token');
        // if(user_id === 'tathagat.malviya@constems-ai.com') {
    //   if(user_id == 'priyaranjan.kumar@constems-ai.com') {
    //         // url = ip+port+'/lab?token='+token;
    //         url = 'http://20.197.24.33:52234/lab?token=765c1c326ff3b2fa6625725016a9183e9e8e1af430397ef0';

    //     }
    //     else if(user_id == 'avaneesh.singh@vdtsolution.com') {
    //         url = 'http://20.197.24.33:52234/lab?token=765c1c326ff3b2fa6625725016a9183e9e8e1af430397ef0';

    //     }
    //     else {
    //         // url = 'http://20.197.24.33:52234/lab?token=765c1c326ff3b2fa6625725016a9183e9e8e1af430397ef0';
    //         url = ip+':'+port+'/lab?token='+token;
    //         // url = this.state.url;
    //     }
        // console.log(url)
        // url = ip+':'+port+'/lab/lab?token='+token;
        url = ip;
        return (
            // <div style={{
            //     width: '100%', height: '100vh', display: "flex",
            //     justifyContent: "center",
            //     backgroundColor: 'rgba(255, 255, 255, 1)'
            // }}>
            
            <div style={{
                width: '100%', height: '100vh', display: "flex",
                justifyContent: "center",
                backgroundColor:'rgba(255, 255, 255, 1)'
                // alignItems: "center",
            }}>

                {/* <div style={{float:'right', color:'white'}}><p>{user_name}</p></div> */}
                <Sidebar pageNumber='2'></Sidebar>
                <Header user_name= {user_name}></Header>
                {/* <iframe src="http://plnkr.co/" style={{width:'100%', height:'90vh'}} width="500"/> */}
                {/* <iframe src="https://caiswebapp-iat.azurewebsites.net/"  style={{width:'100%'}}></iframe> */}
                {/* <iframe is="x-frame-bypass" src="http://192.168.1.201:31190/" sandbox="Access-Control-Allow-Origin *" style={{width:'100%', height:'90vh'}}/> */}
                {/* <iframe  src="http://192.168.1.201:31190/"  style={{width:'100%', visibility:'visible'}}/> */}
                {/* <iframe  src="http://192.168.1.201:51234/"  style={{width:'100%', visibility:'visible'}}/> */}
                {/* <iframe src="http://20.197.24.33:52234/lab?token=55cd6c44f0d7d12139cf3f267e3138f3019f4cd0857f5cb8" style={{ width: '100%', visibility: 'visible' }} /> */}
              
                <iframe src={url} style={{ width: '100%', height:'93vh', marginTop:'54px',  visibility: 'visible' }} />
              
            </div>
        );
    }
}

export default Workspace;