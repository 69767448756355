import React, {useEffect} from "react";
import {BrowserRouter as Router, Switch, Route, useHistory, HashRouter} from "react-router-dom";
import logo from './logo.svg';
import './App.css';
import Login from "./component/login/Login";
import Signup from "./component/signup/Signup";
import Home from "./component//pages/Home";
import Volume from "./component/pages/Volume";
import Workspace from "./component/pages/Workspace";
import Header from "./component/header/Header";
import {Helmet} from "react-helmet";


function App() {
  const TITLE = "Prakriti AI"
  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
    <HashRouter>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/Signup" component={Signup} />
        <Route exact path="/Home" component={Home} />
        <Route exact path="/Volume" component={Volume} />
        <Route exact path="/Workspace" component={Workspace} />
        <Route exact path="/Header" component={Header} />
        </Switch>
        </HashRouter>
      </>
  );
}

export default App;
